<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
            <div class="mb-3">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item>Hotels</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
  
            <h3
              class="mb-4"
              style="font-size: 1.1em; font-weight: 600;"
            >Hotels:</h3>
  
            <div class="search_add_section">
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by Name"
                  class="search_by_input"
                  spellcheck="off"
                  style="width: 100%;"
                />
              </div>
              <div>
  
                <el-button
                  type="primary"
                  class="z-depth-0"
                  @click="$router.push({path: '/add-hotel'})"
                >Add A Hotel</el-button>
              </div>
            </div>
            <hr />
  
            <div
              v-loading="loading || deleting"
              class="table-responsive"
            >
              <data-tables
                :data="countries"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getCountries"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load Countries Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No Countries</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="50px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Country"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="auto"
                >
                  <template slot-scope="scope">
  
                    <img
                      :src="scope.row.featured_photo"
                      style="width: 50px; height: 50px; object-fit: cover;"
                    >
                    <span> <strong> </strong> {{ scope.row.country_title }} </span><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Operations"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="auto"
                >
                  <template slot-scope="scope">
                    <el-button
                      @click="$router.push({ path: `/${scope.row.country_title}/destinations/${scope.row.id}` })"
                      type="primary"
                      round
                    >{{ scope.row.destination_count }} Destinations</el-button>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Country Overview"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                  width="auto"
                >
                  <template slot-scope="scope">
                    {{ scope.row.overview | truncate(50, '...') }}
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                      <el-tooltip
                        content="Edit"
                        placement="top"
                      >
                        <el-button
                          @click="$router.push({path: `/edit-country/${scope.row.id}`})"
                          type="warning"
                          icon="el-icon-edit"
                          circle
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip
                        content="Delete"
                        placement="top"
                      >
                        <el-button
                          @click="deleteCountry(scope.row.id)"
                          type="danger"
                          icon="el-icon-delete"
                          circle
                        ></el-button>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
  
              </data-tables>
            </div>
  
          </div>
        </article>
      </section>
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/scale-out-component.vue";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
    },
  
    data() {
      return {
        isLoading: false,
        loading: false,
        loadingError: false,
        deleting: false,
        isEditPackageDialogVisible: false,
  
        countries: [],
        safariPackage: {},
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "room_name",
            value: "",
          },
        ],
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getCountries();
    },
  
    methods: {
      showEditPackageDialog(safariPackage) {
        this.isEditPackageDialogVisible = true;
        this.safariPackage = safariPackage;
      },
  
      closeEditPackageDialog() {
        this.isEditPackageDialogVisible = false;
      },
  
      async getCountries() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(
            `api/safari/destination/country/get_all`
          );
          if (request.data.success) {
            // this.lecturerCourseUnits = request.data.lecturer_course_units;
            this.countries = request.data.countries;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch Countries now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async switchStatus(package_id) {
        try {
          this.loading = true;
          let request = await this.$http.get(
            `api/safari/package/set_package_status?package_id=${package_id}`
          );
          if (request.data.success) {
            this.showSuccessMessage("Success", "Package status changed");
            this.loading = false;
            this.getCountries();
          } else {
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.loading = false;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to change Package status",
            "An unexpected error occurred, please try again"
          );
        } finally {
          this.loading = false;
        }
      },
  
      async deleteCountry(id) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Country. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(
                `api/safari/destination/country/delete/${id}`
              );
              if (
                request.data.success &&
                request.data.message === "DESTINATION_COUNTRY_DELETED"
              ) {
                this.getCountries();
                this.$notify({
                  title: "Success",
                  message: "Country deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete Country, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
    },
  };
  </script>
  
  <style scoped>
  * {
    box-sizing: border-box;
  }
  
  input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #ffffff;
    font-size: 0.9em;
  }
  
  input:focus {
    border: 0.01em solid #409eff !important;
  }
  
  .search_add_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  </style>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>