<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
  
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <BreadCrumbComponent :pageTitles="['Newsletter Subscribers']" style="color: #083628" />
              </div>
            </div>
  
            <h4><b>Newsletter subscribers:</b></h4>
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by Name"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
              <!-- <div>
                <AddUserDialog @re-fresh="getUsers" />
              </div> -->
            </div>
            <hr />
  
            <div
              v-loading="loading"
              class="table-responsive mb-5"
            >
              <data-tables
                :data="users"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getUsers"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load Users Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No Users</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="80px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Name"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.full_name }} </span><br />
                  </template>
                </el-table-column>
  
                
  
                <el-table-column
                  label="Email"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.email }} </span><br />
                  </template>
                </el-table-column>
  

  <el-table-column
                  label="Date Subscribed"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.date_created }} </span><br />
                  </template>
                </el-table-column>
  
                
  
               
  
                
  
                
  
              </data-tables>
            </div>
  
          </div>
        </article>
      </section>
  
     
  
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/scale-out-component.vue";
  import BreadCrumbComponent from "../components/bread-crumb-component";
  
  
  import { format, parseISO } from "date-fns";
  import jwt_decode from "jwt-decode";
  export default {
    components: {
      ScaleOut,
      BreadCrumbComponent
      
      
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        showUserEditDialog: false,
        users: [],
        user_data: {},
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "fullname",
            value: "",
          },
        ],
        loadingError: false,
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getUsers();
    },
  
    methods: {
      showEditUserData(userData) {
        this.showUserEditDialog = true;
        this.user_data = userData;
      },
  
      closeEditUserData() {
        this.showUserEditDialog = false;
      },
  
      async getUsers() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`safari_tours/get_newsletter_subscribers`);
          if (request.data.success) {
            this.users = request.data.subs;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch Users now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async deleteUser(id) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this User. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`api/user/delete/${id}`);
              if (
                request.data.success &&
                request.data.message === "USER_DELETED_SUCCESSFULLY"
              ) {
                this.getUsers();
                this.$notify({
                  title: "Success",
                  message: "User deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete the User, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
    },
  };
  </script>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>