<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
  
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <BreadCrumbComponent :pageTitles="['Ihamba Receipts']" />
              </div>
            </div>
  
            <h3><b>Ihamba Receipts:</b></h3>
  
           
  
            <div class="search_add_section">
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by name"
                  class="search_by_input"
                  spellcheck="off"
                  style="width: 100%;"
                />
              </div>
  
              <div>
                <AddReceiptDialog />
              </div>
  
            </div>
            <hr />
            <div
              v-loading="loading || deleting"
              class="table-responsive"
            >
              <data-tables
                :data="quotes"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getQuotations"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load Receipts Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No Receipts</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="50px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Client Name"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="auto"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.name }} </span>
       
                  </template>
                </el-table-column>

                <el-table-column
                  label="Item /Package"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="auto"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.item }} </span><br />
                  </template>
                </el-table-column>

                
                <el-table-column
                  label="Receipt No:"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="auto"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.receipt_no }} </span><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Amount Paid"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                  width="auto"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.amount }} </span>
                  </template>
                </el-table-column>
  
              


                <el-table-column
                  label="Balance"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="auto"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.balance }} </span><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                      <el-tooltip
                        content="View"
                        placement="top"
                      >
                        <el-button
                          @click="viewReceipt(scope.row.id)"
                          type="success"
                          icon="el-icon-view"
                          circle
                        ></el-button>
                      </el-tooltip>
                     
                    </el-row>
                  </template>
                </el-table-column>
  
              </data-tables>
            </div>
  
          </div>
        </article>
      </section>
  
      <EditSafariPackageDialog
        :isEditPackageDialogVisible="isEditPackageDialogVisible"
        :safariPackage="safariPackage"
        @closeEditPackageDialog="closeEditPackageDialog"
        @refreshPackages="getQuotations"
      />
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/scale-out-component.vue";
  import BreadCrumbComponent from "../components/bread-crumb-component";
  import AddReceiptDialog from "../components/dialogs/add-reciept.vue";
  import { format, parseISO } from "date-fns";
  import EditSafariPackageDialog from "../components/dialogs/edit-safari-package-dialog.vue";
  export default {
    components: {
      EditSafariPackageDialog,
      ScaleOut,
      BreadCrumbComponent,
      AddReceiptDialog
    },
  
    data() {
      return {
        isLoading: false,
        loading: false,
        loadingError: false,
        deleting: false,
        isEditPackageDialogVisible: false,
        showRatesDialogVisible: false,
  
        quotes: [],
        safariPackage: {},
        packageId: "",
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "name",
            value: "",
          },
        ],
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy");
      },
    },
  
    mounted() {
      this.getQuotations();
    },
  
    methods: {
      showEditPackageDialog(safariPackage) {
        this.isEditPackageDialogVisible = true;
        this.safariPackage = safariPackage;
      },
  
      closeEditPackageDialog() {
        this.isEditPackageDialogVisible = false;
      },
  
      showRatesDialog(packageId) {
        this.showRatesDialogVisible = true;
        this.packageId = packageId;
      },
  
      closeRatesDialog() {
        this.showRatesDialogVisible = false;
      },
  
      async getQuotations() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
         
          let request = await this.$http.get(`ReceiptGenAlgorithm/Receipts`);
          if (request.data.success) {
            this.quotes = request.data.receipts;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch Quotes now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async switchStatus(package_id) {
        try {
          this.loading = true;
          let request = await this.$http.get(
            `api/safari/package/set_package_status?package_id=${package_id}`
          );
          if (request.data.success) {
            this.showSuccessMessage("Success", "Package status changed");
            this.loading = false;
            this.getQuotations();
          } else {
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.loading = false;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to change Package status",
            "An unexpected error occurred, please try again"
          );
        } finally {
          this.loading = false;
        }
      },
  
      async deletePackage(id) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this item. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(
                `api/safari/package/delete/${id}`
              );
              if (
                request.data.success &&
                request.data.message === "safari_tour deleted successfully"
              ) {
                this.getQuotations();
                this.$notify({
                  title: "Success",
                  message: "Package deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete Package, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },

      async viewReceipt(id) {

        this.loading = true;
        try {
        this.submitting = true;
        

        const response = await this.$http.get(`ReceiptGenAlgorithm/download_receipt/${id}`,  {
          responseType: 'arraybuffer'
        });

        if (
          response
        ) {
         
          // Create a Blob object from the response data
          const blob = new Blob([response.data], { type: 'application/pdf' });

          // Create a URL for the Blob object
          const url = URL.createObjectURL(blob);

          // Create a temporary link element
          const link = document.createElement('a');
          link.href = url;

          // Set the filename for the downloaded file

          link.download = "Ihamba_receipt.pdf";

          // Trigger the download
          link.click();
          
          this.loading = false;
        } else {
          alert('wh')
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.$notify({
            title: "Connection failed",
            message: "A network error occurred, please try again.",
            type: "error",
          });
        }
        console.log(error);
        this.$notify({
          title: "Unable to add user",
          message: "An unexpected Error occurred, please try again",
          type: "error",
        });
      } finally {
        this.submitting = false;
      }

      },
    },
  };
  </script>
  
  <style scoped>
  * {
    box-sizing: border-box;
  }
  
  input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #ffffff;
    font-size: 0.9em;
  }
  
  input:focus {
    border: 0.01em solid #409eff !important;
  }
  
  .search_add_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  </style>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>