<template>
  <div>
    <button
      class="btn"
      v-if="$store.state.currentRole == 'ADMIN'"
      style="background-color: #083628; color: white"
      @click="showAddUserModal"
    >
      Generate Receipt
    </button>
    <article>
      <section>
        <div class="courseUnits_dialogs">
          <el-dialog
            :title="`Generate Receipt`"
            :visible.sync="addUserModal"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="submitting">
              <el-form
                :model="userForm"
                :rules="rules"
                label-position="top"
                ref="userForm"
                class="demo-userForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Full Name" prop="name">
                      <el-input v-model="userForm.name"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <el-form-item label="Amount" prop="amount">
                      <el-input v-model="userForm.amount"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Balance" prop="balance">
                      <el-input v-model="userForm.balance"></el-input>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <el-form-item label="Payment Method" prop="payment_method">
                      <el-input v-model="userForm.payment_method"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Item / Package" prop="package">
                      <el-input v-model="userForm.package"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <el-form-item label="Package Link" prop="link">
                      <el-input v-model="userForm.link"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <el-form-item label="Date" prop="date">
                      <el-date-picker v-model="userForm.date"></el-date-picker>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    style="background-color: #083628; color: white"
                    @click="addUser('userForm')"
                    >Generate Receipt</el-button
                  >
                </el-form-item>
              </el-form>
              <br />
              <div class="text-center">
                <div class="text-center">
                  <small>© 2023 Ihamba Adventures | All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="userForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      bookings: [],
      users: [],
      userEditId: "",
      selectedUserPhotoFile: null,
      isUploadingFile: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      userPhotoFileLists: [],
      countries: [],

      options: [
        {
          value: "AGENT",
          label: "Agent",
        },
        {
          value: "ADMIN",
          label: "Admin",
        },
      ],

      addUserModal: false,
      editUserModal: false,
      years: "",
      year_numbers: [],
      value: "",
      loadingError: false,
      submitting: false,
      course: "",
      userForm: {
        name: "",
        amount: "",
        balance: "",
        description: "",
        payment_method: "",
        item: "",
        date: "",
        link: "",
      },

      rules: {
        name: [
          { required: true, message: "Full Name is required", trigger: "blur" },
        ],
        amount: [
          { required: true, message: "Amount is required", trigger: "blur" },
        ],
        balance: [
          {
            required: true,
            message: "balance is required",
            trigger: "blur",
          },
        ],

        date: [
          {
            required: true,
            message: "date is required",
            trigger: "change",
          },
        ],
        payment_method: [
          {
            required: true,
            message: "payment_method is required",
            trigger: "blur",
          },
        ],
        link: [
          {
            required: true,
            message: "package link is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    showAddUserModal() {
      this.addUserModal = true;
    },

    async addUser(formName) {
  this.$refs[formName].validate(async (valid) => {
    if (valid) {
      try {
        this.submitting = true;

        const formData = new FormData();
        formData.append("fullname", this.userForm.name);
        formData.append("amount", this.userForm.amount);
        formData.append("balance", this.userForm.balance);
        formData.append("package", this.userForm.package);
        formData.append("payment_method", this.userForm.payment_method);
        formData.append("link", this.userForm.link);
        formData.append("date", this.userForm.date);


        const response = await this.$http.post('ReceiptGenAlgorithm/Receipt', formData, {
          responseType: 'arraybuffer'
        });

        if (
          response
        ) {
          
         
          // Create a Blob object from the response data
          const blob = new Blob([response.data], { type: 'application/pdf' });

          // Create a URL for the Blob object
          const url = URL.createObjectURL(blob);

          // Create a temporary link element
          const link = document.createElement('a');
          link.href = url;

          // Set the filename for the downloaded file

          link.download = `${this.userForm.name}_ihamba.pdf`; // Use the extracted filename or fallback to a default name


          // Trigger the download
          link.click();
          this.$refs[formName].resetFields();
          this.addUserModal = false;
        } else {
          alert('wh')
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.$notify({
            title: "Connection failed",
            message: "A network error occurred, please try again.",
            type: "error",
          });
        }
        console.log(error);
        this.$notify({
          title: "Unable to add user",
          message: "An unexpected Error occurred, please try again",
          type: "error",
        });
      } finally {
        this.submitting = false;
      }
    } else {
      return false;
    }
  });
},

     getFilenameFromHeaders(headers) {
  const contentDisposition = headers['Content-Disposition'];
  if (contentDisposition) {
    const match = contentDisposition.match(/filename="(.*?)"/);
    if (match) {
      return match[1];
    }
  }
  return null;
}


    
  },
};
</script>
    
    <style>
</style>